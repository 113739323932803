import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueSvgInlinePlugin from "vue-svg-inline-plugin";
import vSelect from "./../node_modules/vue-select/src/index.js";
import { createPinia, mapStores } from "pinia";
import useStore from "./store";

const app = createApp(App)
  .use(router)
  .use(VueSvgInlinePlugin)
  .use(createPinia())
  .component("vSelect", vSelect);

app.mixin({
  computed: {
    ...mapStores(useStore),
  },
});

app.mount("#app");
