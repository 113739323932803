<template>
  <div
    class="mobile-menu"
    :class="[isMenuOpen ? 'mobile-menu--open' : 'mobile-menu--closed']"
  >
    <div
      class="mobile-menu__backdrop"
      v-if="isMenuOpen"
      @click="closeMenu()"
    ></div>
    <div
      class="mobile-menu__panel"
      :class="[
        isMenuOpen
          ? 'mobile-menu__panel--opened'
          : 'mobile-menu__panel--closed',
      ]"
    >
      <router-link to="/" class="mobile-menu__link" @click="closeMenu()">
        Bar
      </router-link>
      <router-link to="/results" class="mobile-menu__link" @click="closeMenu()">
        Recipes
      </router-link>
      <router-link to="/help" class="mobile-menu__link" @click="closeMenu()">
        Help
      </router-link>
      <router-link to="/info" class="mobile-menu__link" @click="closeMenu()">
        Info
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileMenu",
  computed: {
    isMenuOpen() {
      return this.mainStore.isMenuOpen;
    },
  },
  methods: {
    closeMenu() {
      this.mainStore.closeMenu();
    },
  },
};
</script>
