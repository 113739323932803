<template>
  <header class="main-header">
    <div class="main-header__width-box">
      <div
        class="main-header__stock-nav mobile"
        v-if="$route.path !== '/help'"
        @click="
          closeMenu();
          openStockNav();
        "
      >
        <img alt="shelf icon" src="./assets/svg/shelf.svg" v-svg-inline />
      </div>
      <div class="main-header__nav desktop">
        <router-link to="/" class="main-header__nav-link"> Bar </router-link>
        <router-link to="/results" class="main-header__nav-link">
          Recipes
        </router-link>
      </div>
      <router-link to="/" class="main-header__logo" @click="closeMenu()">
        <img alt="logo icon" src="./assets/svg/logo.svg" v-svg-inline />
      </router-link>
      <div class="main-header__burger mobile" @click="toggleMenu()">
        <img alt="menu icon" src="./assets/svg/menu.svg" v-svg-inline />
      </div>
      <div class="main-header__nav-right desktop">
        <router-link to="/help" class="main-header__nav-link-svg">
          <img alt="help icon" src="./assets/svg/help.svg" v-svg-inline />
        </router-link>
        <router-link to="/info" class="main-header__nav-link-svg">
          <img alt="info icon" src="./assets/svg/info.svg" v-svg-inline />
        </router-link>
      </div>
    </div>
  </header>
  <MobileMenu />
  <router-view />
</template>

<script>
import MobileMenu from "@/components/MobileMenu.vue";

export default {
  name: "App",
  components: {
    MobileMenu,
  },
  created() {
    // Mobile height trick
    var ih = window.innerHeight;
    document.documentElement.style.setProperty("--ih", `${ih}px`);
    window.addEventListener("resize", () => {
      var ih = window.innerHeight;
      document.documentElement.style.setProperty("--ih", `${ih}px`);
    });
    // Data fetchs
    fetch("https://whichcocktailtonight.ew.r.appspot.com/ingredients")
      .then((response) => response.json())
      .then((json) => this.mainStore.initiateIngredients(json));
    fetch("https://whichcocktailtonight.ew.r.appspot.com/recipes")
      .then((response) => response.json())
      .then((json) => this.mainStore.initiateRecipes(json));
  },
  beforeMount: function () {
    if (
      this.mainStore.firstVisit &&
      this.$router.currentRoute.path !== "/help" &&
      this.$router.currentRoute.name !== "recipe" &&
      this.$router.currentRoute.name !== "notFound"
    ) {
      // redirect to help if first visit on website
      // (except for recipe page and not found page)
      this.$router.push("/help");
      window.localStorage.setItem("firstVisit", false);
    }
  },
  methods: {
    toggleMenu() {
      this.mainStore.toggleMenu();
    },
    closeMenu() {
      this.mainStore.closeMenu();
    },
    openStockNav() {
      this.mainStore.openStockNav();
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/style.scss";
@import "@/styles/blocks/category-selector.scss";
@import "@/styles/blocks/component.scss";
@import "@/styles/blocks/credits-page.scss";
@import "@/styles/blocks/find-button.scss";
@import "@/styles/blocks/help-page.scss";
@import "@/styles/blocks/home-page.scss";
@import "@/styles/blocks/info-page.scss";
@import "@/styles/blocks/ingredient-button.scss";
@import "@/styles/blocks/ingredient-search.scss";
@import "@/styles/blocks/loader.scss";
@import "@/styles/blocks/main-header.scss";
@import "@/styles/blocks/mobile-menu.scss";
@import "@/styles/blocks/not-found.scss";
@import "@/styles/blocks/recipe-button.scss";
@import "@/styles/blocks/recipe-page.scss";
@import "@/styles/blocks/results-page.scss";
@import "@/styles/blocks/share.scss";
@import "@/styles/blocks/stock-box.scss";
@import "@/styles/blocks/stocked-ingredient.scss";
@import "@/styles/blocks/toggle.scss";
</style>
