import { defineStore } from "pinia";

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
const useStore = defineStore("main", {
  state: () => {
    return {
      ingredients: [],
      recipesRef: [],
      favoriteList: window.localStorage.getItem("favoriteList")
        ? JSON.parse(window.localStorage.getItem("favoriteList"))
        : [],
      bar: window.localStorage.getItem("bar")
        ? JSON.parse(window.localStorage.getItem("bar"))
        : [],
      firstVisit: window.localStorage.getItem("firstVisit") ? false : true,
      isMenuOpen: false,
      isStockOpen: false,
    };
  },
  actions: {
    initiateIngredients(ingredients) {
      this.ingredients = ingredients;
    },
    initiateRecipes(recipes) {
      this.recipesRef = recipes;
    },
    saveBar() {
      window.localStorage.setItem("bar", JSON.stringify(this.bar));
    },
    saveFavorite() {
      window.localStorage.setItem(
        "favoriteList",
        JSON.stringify(this.favoriteList)
      );
    },
    emptyStock() {
      this.bar = [];
      this.saveBar();
    },
    addToStock(ingredient) {
      if (!this.bar.includes(ingredient.id)) {
        this.bar.push(ingredient.id);
      }
      this.saveBar();
    },
    removeFromStock(ingredient) {
      const index = this.bar.indexOf(ingredient.id);
      if (index > -1) {
        this.bar.splice(index, 1);
      }
      this.saveBar();
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    openStockNav() {
      this.isStockOpen = true;
    },
    closeStockNav() {
      this.isStockOpen = false;
    },
    toggleFavorite(recipe) {
      if (this.favoriteList.includes(recipe.id)) {
        this.favoriteList.splice(this.favoriteList.indexOf(recipe.id), 1);
      } else {
        this.favoriteList.push(recipe.id);
      }
      this.saveFavorite();
    },
  },
  getters: {
    stockIngredients(state) {
      return state.ingredients.filter((ingredient) =>
        state.bar.includes(ingredient.id)
      );
    },
    ingredientsLoaded(state) {
      return state.ingredients.length !== 0;
    },
    recipesLoaded(state) {
      return state.recipesRef.length !== 0;
    },
    recipes(state) {
      let that = this;
      return state.recipesRef
        .map(function (recipe) {
          recipe.components.sort(function compare(a, b) {
            //sort optional ingredients at the end
            if (a.optional) {
              return 1;
            }
            if (b.optional) {
              return -1;
            } else {
              //sort depending on categories
              var ingr_a = state.ingredients.find(
                (element) => element.id === a.ingredient
              );
              var ingr_b = state.ingredients.find(
                (element) => element.id === b.ingredient
              );
              if (ingr_a.category["order"] < ingr_b.category["order"]) {
                return -1;
              }
              if (ingr_a.category["order"] > ingr_b.category["order"]) {
                return 1;
              } else {
                //sort with bigger quantities first
                if (a.quantity === null) {
                  return 1;
                }
                if (b.quantity === null) {
                  return -1;
                }
                if (
                  parseInt(a.quantity.match(/^\d+/), 10) >
                  parseInt(b.quantity.match(/^\d+/), 10)
                ) {
                  return -1;
                }
                return 1;
              }
            }
          });
          return recipe;
        })
        .map((recipe) => ({
          ...recipe,
          // add number of missing ingredients
          missing: recipe.components
            .map(function (component) {
              if (component.optional) {
                return 0;
              } else {
                if (
                  that.stockIngredients
                    .map((ingredient) => ingredient.id)
                    .includes(component.ingredient)
                ) {
                  return 0;
                } else {
                  return 1;
                }
              }
            })
            .reduce((a, b) => a + b),
          // add boolean to check if at least one ingredient is in stock
          oneinstock: recipe.components
            .map((component) =>
              that.stockIngredients
                .map((ingredient) => ingredient.id)
                .includes(component.ingredient)
            )
            .some((elt) => elt),
        }))
        .sort(function (a, b) {
          return a.missing - b.missing;
        });
    },
    ingredientCategoryList(state) {
      return state.ingredients
        .map((ingredient) => ingredient.category)
        .reduce(
          (unique, item) =>
            unique.some((element) => item["key"] === element["key"])
              ? unique
              : [...unique, item],
          []
        );
    },
  },
});

export default useStore;
