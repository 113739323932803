import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "bar-builder",
    component: () => import("../views/BarBuilder.vue"),
    meta: { title: "Which Cocktail Tonight" },
  },
  {
    path: "/results",
    name: "results",
    component: () => import("../views/Results.vue"),
    meta: { title: "Results | Which Cocktail Tonight" },
  },
  {
    path: "/recipe/:id/:name",
    name: "recipe",
    props: true,
    component: () => import("../views/Recipe.vue"),
    meta: { title: "Recipe | Which Cocktail Tonight" },
  },
  {
    path: "/info",
    name: "info",
    component: () => import("../views/Info.vue"),
    meta: { title: "Info | Which Cocktail Tonight" },
  },
  {
    path: "/credits",
    name: "credits",
    component: () => import("../views/Credits.vue"),
    meta: { title: "Credits | Which Cocktail Tonight" },
  },
  {
    path: "/help",
    name: "help",
    component: () => import("../views/Help.vue"),
    meta: { title: "Help | Which Cocktail Tonight" },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: () => import("../views/NotFound.vue"),
    meta: { title: "Not Found | Which Cocktail Tonight" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    !window.localStorage.getItem("firstVisit") &&
    (from.name == "recipe" || from.name == "notFound")
  ) {
    //redirect to help when leaving recipe page if first visit on website
    window.localStorage.setItem("firstVisit", false);
    next("/help");
  } else {
    next();
  }
  document.title = to.meta.title;
});

export default router;
